export const addToRefs = (ref, el) => {
  if (el && !ref.current.includes(el)) {
    ref.current.push(el);
  }
};

export function debounce(func, wait, immediate) {
  let timeout;
  return function () {
    const context = this;
    // eslint-disable-next-line prefer-rest-params
    const args = arguments;
    const later = function () {
      timeout = null;
      if (!immediate) func.apply(context, args);
    };
    const callNow = immediate && !timeout;
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
    if (callNow) func.apply(context, args);
  };
}

/*
export function fadeHeaderFooter(exit, node) {
  const header = document.querySelector('#header');
  const footer = document.querySelector('#footer');
  gsap.to([header, footer], { duration: 0.3, autoAlpha: 0 });
}
*/
