import React, { useContext, forwardRef, useState } from 'react';
import Img from 'gatsby-image';
import styled from 'styled-components';
import { VideoContext } from '../contexts/VideoContext';
import Close from '../assets/images/close.inline.svg';
import PortableText from './PortableText';
import { mq } from '../styles/breakpoints';
import Video from './Video';

const VideoStyles = styled.div`
  overflow: hidden;
  position: relative;
  text-align: center;

  video {
    width: 100%;

    &:focus,
    &:active {
      outline: none;
    }
  }

  .video {
    position: relative;
  }
  .poster {
    background: none;
    border: none;
    cursor: pointer;
    display: block;
    height: 100%;
    padding: 0;
    position: absolute;
    top: 0;
    width: 100%;

    &.inactive {
      display: none;
    }
  }
  .play {
    display: none;
    height: 10.0625rem;
    left: 50%;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 7.0625rem;

    ${mq[1]} {
      display: block;
    }
  }

  ${mq[3]} {
    width: 66%;
  }
`;

const CardDetailStyles = styled.div`
  max-height: 0;
  overflow: hidden;
  position: relative;
  transition: max-height 0.6s ease-out 0.6s;

  .video__text {
    margin-bottom: 3rem;
  }

  ${mq[3]} {
    display: flex;

    .gatsby-image-wrapper {
      /* margin-bottom: 1.6875rem; */
    }

    .video__text {
      margin-bottom: 1.6875rem;
      padding-left: 1.6875rem;
      width: 34%;
    }
  }

  &.active {
    /* height: auto; */
    max-height: 800px;
  }

  .close-video {
    cursor: pointer;
    position: absolute;
    right: 0;
    top: 0.3125rem;
    width: 1.25rem;
  }
  .single__title {
    align-items: center;
    display: flex;
    font-family: var(--font-primary);
    font-size: 2rem;
    font-weight: normal;
    justify-content: space-between;
    margin: 1.5rem 0;
    padding-right: 1.875rem;
    position: relative;
    width: 100%;

    ${mq[3]} {
      margin: 0 0 1.5rem;
    }

    svg {
      font-size: rem-calc(14px);
    }
  }
  .video-text {
    max-width: 37.5rem;

    /* > *:first-child:first-letter {
      display: block;
      font-size: 1.5em;
      font-weight: bold;
      padding-right: 1px;
      text-transform: uppercase;
    } */
  }
`;

const RealisationContent = ({ realisation, index }, ref) => {
  const { title, video, subtitle, _rawText, bigImage } = realisation;

  const { selectedVideo, setSelectedVideo } = useContext(VideoContext);

  const [isPlaying, setIsPlaying] = useState(false);

  const handleChange = (e, i) => {
    setSelectedVideo(selectedVideo === i ? null : i);
    setIsPlaying(!isPlaying);
  };

  return (
    <CardDetailStyles
      className={`${selectedVideo === index ? 'active' : ''}`}
      ref={ref}
    >
      {video && (
        <VideoStyles>
          <Video
            index={index}
            video={video}
            poster={bigImage.asset.fluid}
            isPlaying={isPlaying}
            setIsPlaying={setIsPlaying}
          />
        </VideoStyles>
      )}
      {!video && (
        <VideoStyles>
          <Img fluid={bigImage.asset.fluid} alt={title} />
        </VideoStyles>
      )}
      <div className="video__text">
        <h3 className="single__title">
          <span>{title}</span>
          <Close
            className="close-video"
            onClick={(e) => handleChange(e, index)}
          />
        </h3>
        {subtitle && <h4>{subtitle}</h4>}
        <div className="entry-content">
          <PortableText blocks={_rawText} id="video-text" />
        </div>
      </div>
    </CardDetailStyles>
  );
};

export default forwardRef(RealisationContent);
