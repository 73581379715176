/* eslint-disable react/display-name */
import React from 'react';
import { graphql } from 'gatsby';
import { MapToComponents } from 'react-map-to-components';
// import Layout from '../components/Layout';
import Intro from '../components/sections/Intro';
import SEO from '../components/SEO';
import TwoColumns from '../components/sections/TwoColumns';
import Sentence from '../components/sections/Sentence';
import Head from '../components/Head';
import Realisations from '../components/sections/Realisations';

export default function SinglePage({
  // transitionStatus,
  pageContext,
  data: { singlePage, networks, intro },
}) {
  const { title, titleSeo, descriptionSeo, content } = singlePage;
  const sentences = intro?.content[0]?._rawSentences;

  /*
  const curtainsRef = useRef(null);
  const headerRef = useRef(null);

  useEffect(() => {
    const curtains = curtainsRef.current.querySelectorAll('div');
    const header = document.querySelector('#header');
    const footer = document.querySelector('#footer');

    function moveCurtain(curtain) {
      gsap.to(curtain, {
        duration: 0,
        x: '100%',
        ease: 'none',
      });
    }

    if (transitionStatus === 'entering') {
      // console.log('entering');
      gsap.to([header, footer], { duration: 0.3, autoAlpha: 0 });
      curtains.forEach((curtain, i) => {
        gsap.to(curtain, {
          duration: 0.6,
          x: 0,
          ease: 'power2',
          delay: i * 0.1,
        });
      });
    }
    if (transitionStatus === 'exiting') {
      // console.log('exiting');
      gsap.to([header, footer], { duration: 0.3, autoAlpha: 0 });
    }
    if (transitionStatus === 'exited') {
      // console.log('exited');
    }
    if (transitionStatus === 'entered') {
      // console.log('entered');
      gsap.to([header, footer], { duration: 0.3, autoAlpha: 1, delay: 0.65 });
      curtains.forEach((curtain, i) => {
        gsap.to(curtain, {
          duration: 0.6,
          x: '-100%',
          ease: 'power2',
          delay: i * 0.1,
          onComplete: () => moveCurtain(curtain),
        });
      });
    }
  }, [transitionStatus]);
  */

  return (
    <>
      <SEO title={titleSeo || title} description={descriptionSeo} />
      {/* {pageContext.slug === 'nous-contacter' && <Form />} */}
      {(pageContext.slug === 'a-propos' ||
        pageContext.slug === 'mentions-legales') && (
        <Head networks={networks} />
      )}
      {pageContext.slug === 'realisations' && (
        <Head sentences={sentences} networks={networks} />
      )}

      <MapToComponents
        getKey={(section) => section.id || section._key}
        getType={(section) => section._type}
        list={content}
        map={{
          intro: (props) => (
            <Intro /* transitionStatus={transitionStatus} */ {...props} />
          ),
          twoColumns: TwoColumns,
          sentence: Sentence,
          realisations: Realisations,
        }}
        mapDataToProps={{
          intro: ({ data }) => ({
            sentences: data._rawSentences,
          }),
          twoColumns: ({ data }) => ({
            columnLeft: data._rawLeftColumn,
            columnRight: data._rawRightColumn,
            className: data.className,
          }),
          sentence: ({ data }) => ({
            sentence: data._rawText,
          }),
          realisations: ({ data }) => ({
            realisationsArray: data.realisationsArray,
          }),
        }}
      />
    </>
  );
}

export const query = graphql`
  query($slug: String!) {
    networks: sanitySingletonSite(_id: { eq: "singletonSite" }) {
      facebook
      linkedin
      vimeo
      twitter
    }
    intro: sanityPage(slug: { current: { eq: "home" } }) {
      content {
        ... on SanityIntro {
          _key
          _type
          _rawSentences(resolveReferences: { maxDepth: 10 })
        }
      }
    }
    singlePage: sanityPage(slug: { current: { eq: $slug } }) {
      descriptionSeo
      title
      titleSeo
      titleMenu
      slug {
        current
      }
      content {
        ... on SanityIntro {
          _key
          _type
          _rawSentences(resolveReferences: { maxDepth: 10 })
        }
        ... on SanityRealisations {
          _key
          _type
          realisationsArray {
            id
            title
            video {
              _key
              _type
              _rawAsset(resolveReferences: { maxDepth: 10 })
            }
            subtitle
            tags {
              title
              id
            }
            slug {
              current
            }
            _rawText
            vignette: image {
              asset {
                fluid(maxWidth: 600, maxHeight: 300) {
                  ...GatsbySanityImageFluid
                }
              }
            }
            bigImage: image {
              asset {
                fluid(maxWidth: 1200, maxHeight: 600) {
                  ...GatsbySanityImageFluid
                }
              }
            }
            colorlist {
              value
            }
          }
        }
        ... on SanitySentence {
          _key
          _type
          _rawText(resolveReferences: { maxDepth: 10 })
        }
        ... on SanityTwoColumns {
          _key
          _type
          _rawLeftColumn(resolveReferences: { maxDepth: 10 })
          _rawRightColumn(resolveReferences: { maxDepth: 10 })
          className
        }
      }
    }
  }
`;
