import React, { useContext, useRef } from 'react';
import Img from 'gatsby-image';
import styled from 'styled-components';
import gsap from 'gsap';
import { ScrollToPlugin } from 'gsap/ScrollToPlugin';
import { VideoContext } from '../contexts/VideoContext';
import { aspectRatio, pxtopc } from '../styles/Mixins';
import { mq } from '../styles/breakpoints';

if (typeof window !== 'undefined') {
  gsap.registerPlugin(ScrollToPlugin);
}

const CardHeadStyles = styled.div`
  /* ${aspectRatio(350, 202)}; */
  cursor: pointer;
  overflow: hidden;
  position: relative;
  margin-bottom: 1.6875rem;
  width: 100%;

  .open-card-detail {
    background: none;
    border: none;
    cursor: pointer;
    height: 100%;
    padding: 0;
    width: 100%;

    &:focus,
    &:active {
      outline: none;
    }
  }

  .card-head {
    height: 100%;
    position: absolute;
    top: 0;
    width: 100%;
  }

  .card__img {
    opacity: 1;
    position: relative;
    transition: opacity 400ms linear;
  }

  .gatsby-image-wrapper {
    div[aria-hidden='true'] {
      padding-bottom: 57.71% !important;
    }
  }

  .mask,
  img {
    object-fit: cover;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1;
  }

  .mask {
    opacity: 0.6;
    transition: opacity 300ms ease-in;
    will-change: opacity;
    z-index: 2;

    ${mq[1]} {
      opacity: 0;
    }
  }

  .entry-header {
    left: 50%;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 90%;
    z-index: 3;
  }

  .realisation__title {
    opacity: 1;
    text-align: center;
    transition: opacity 300ms ease-in, transform 300ms ease-out;

    ${mq[1]} {
      opacity: 0;
      transform: translateY(-20px);
    }
  }

  .terms {
    opacity: 1;
    text-align: center;
    transition: opacity 300ms ease-in 200ms, transform 300ms ease-out 200ms;

    ${mq[1]} {
      opacity: 0;
      transform: translateY(20px);
    }
  }

  /* .btn--close-card {
    background: none;
    bottom: 1.25rem;
    border: 1px solid var(--white);
    color: var(--white);
    font-size: 0.625rem;
    font-weight: bold;
    display: none;
    left: 50%;
    opacity: 0;
    padding: 2px 10px 4px;
    position: absolute;
    transform: translateX(-50%);
    transition: all 400ms linear;
    visibility: hidden;
    z-index: 2;

    ${mq[1]} {
      bottom: 0.75rem;
    }

    ${mq[2]} {
      bottom: 1.25rem;
      font-size: 0.75rem;
    }
  } */

  &.opened,
  &:hover {
    .mask {
      opacity: 0.96;
    }
    .terms,
    .realisation__title {
      opacity: 1;
      transform: translateY(0);
    }
  }

  &.opened {
    .btn--close-card {
      display: inline-block;
      opacity: 1;
      visibility: visible;
    }
  }

  &.no-hover {
    .mask {
      opacity: 0 !important;
    }
    img {
      transform: none !important;
    }
    .terms,
    .realisation__title {
      opacity: 0 !important;
      transform: none !important;
    }
  }

  ${mq[1]} {
    margin-left: ${pxtopc(28, 1106)};
    width: ${pxtopc(350, 1106)};

    &:nth-child(3n + 1) {
      margin-left: 0;
    }
  }
`;

export default function RealisationHead({ realisation, index }) {
  const { title, tags, vignette, colorlist } = realisation;
  const cardRef = useRef(null);

  const { selectedVideo, setSelectedVideo } = useContext(VideoContext);

  const handleChange = (e, i) => {
    setSelectedVideo(selectedVideo === i ? null : i);
    // setIsPlaying(!isPlaying);

    const el = e.currentTarget;
    const row = el.closest('.rowHeads');
    if (
      typeof window !== 'undefined' &&
      !cardRef.current.classList.contains('opened')
    ) {
      // gsap.to(window, {
      //   duration: 0.6,
      //   ease: 'power2',
      //   scrollTo: { y: row, offsetY: 20 },
      // });
    }
  };

  return (
    <CardHeadStyles
      className={`${selectedVideo === index ? 'opened' : ''} card`}
      ref={cardRef}
    >
      <button
        type="button"
        className="open-card-detail"
        onClick={(e) => handleChange(e, index)}
      >
        <div className="card__img">
          <Img
            fluid={vignette.asset.fluid}
            alt={title}
            width="600"
            height="300"
          />
          <div className="mask" style={{ backgroundColor: colorlist.value }} />
        </div>
        <header className="entry-header">
          <h2 className="realisation__title">{title}</h2>
          {tags &&
            tags.map((tag, i) => (
              <p key={`${tag.id}-${i}`} className="terms">
                {tag.title}
              </p>
            ))}
        </header>
      </button>
    </CardHeadStyles>
  );
}
