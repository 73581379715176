import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { addToRefs } from '../../utils/helpers';
import RealisationContent from '../RealisationContent';
import RealisationHead from '../RealisationHead';

const RowHeadStyles = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

export default function Realisations({ realisationsArray }) {
  const [hasMounted, setHasMounted] = useState(false);

  const contentRefs = useRef([]);
  contentRefs.current = [];

  let windowWidth = 0;

  if (typeof window !== 'undefined') {
    windowWidth = window.innerWidth;
  }

  const [width, setWidth] = React.useState(windowWidth);
  const breakpoint = 768;

  useEffect(() => {
    setHasMounted(true);
  }, []);

  useEffect(() => {
    const handleResizeWindow = () => setWidth(window.innerWidth);
    // subscribe to window resize event "onComponentDidMount"
    window.addEventListener('resize', handleResizeWindow);
    return () => {
      // unsubscribe "onComponentDestroy"
      window.removeEventListener('resize', handleResizeWindow);
    };
  }, []);

  return (
    <div className="list-realisations">
      {hasMounted && (
        <div className="container container--lg">
          <div className="inner">
            {width >= breakpoint &&
              realisationsArray
                .reduce((m, k, i) => {
                  if (i % 3 === 0) {
                    m.push([k]);
                  } else {
                    m[m.length - 1].push(k);
                  }
                  return m;
                }, [])
                .map((grouped, i) => (
                  <div key={`row-${i}`} className="row">
                    <RowHeadStyles className={`rowHeads rowHeads-${i}`}>
                      {grouped.map((realisation, index) => (
                        <RealisationHead
                          key={`head-${realisation.id}`}
                          realisation={realisation}
                          index={index + i * 3}
                        />
                      ))}
                    </RowHeadStyles>
                    <div className="rowContents">
                      {grouped.map((realisation, index) => (
                        <RealisationContent
                          key={`content-${realisation.id}`}
                          realisation={realisation}
                          index={index + i * 3}
                          ref={(el) => addToRefs(contentRefs, el)}
                        />
                      ))}
                    </div>
                  </div>
                ))}
            {width < breakpoint &&
              realisationsArray.map((realisation, index) => (
                <div className="row" key={realisation._key}>
                  <RealisationHead
                    key={`head-${realisation.id}`}
                    realisation={realisation}
                    index={index}
                  />
                  <RealisationContent
                    key={`content-${realisation.id}`}
                    realisation={realisation}
                    index={index}
                  />
                </div>
              ))}
          </div>
        </div>
      )}
    </div>
  );
}
