import React, { useEffect, useState } from 'react';
import gsap from 'gsap';
import { SplitText } from 'gsap/SplitText';
import styled from 'styled-components';
import { Link, navigate } from 'gatsby';
import { mq } from '../../styles/breakpoints';
import PortableText from '../PortableText';
import Arrow from '../../assets/images/arrow.inline.svg';

if (typeof window !== 'undefined') {
  gsap.registerPlugin(SplitText);
}

const IntroStyles = styled.div`
  ${mq[2]} {
    position: relative;
    top: 50%;
    transform: translateY(-50%);
  }

  .fade-slider {
    position: relative;
    width: 100%;
  }

  .slide__title {
    position: relative;
    font-family: var(--font-headings);
    font-size: 2rem;
    opacity: 0;
    text-align: center;
    transition: opacity 200ms linear;

    .wf-active & {
      opacity: 1;
    }
    .text-wrapper {
      position: relative;
      display: inline-block;
      padding-top: 0.2em;
      padding-right: 0.05em;
      padding-bottom: 0.1em;
    }

    ${mq[0]} {
      font-size: 3rem;
    }
    ${mq[1]} {
      font-size: 5rem;
      text-align: left;
    }
  }

  .home-title__slider__item {
    display: none;
  }

  .home-title__slider__item > span {
    display: block;
  }

  .home-title__slider__item--active {
    display: block;
  }
`;

export default function Intro({ sentences }) {
  function goToRealsPage() {
    navigate('/realisations');
  }

  const [anim] = useState(
    gsap.timeline({
      paused: true,
      onComplete: goToRealsPage,
    })
  );

  useEffect(() => {
    const targets = document.querySelectorAll('.home-title__slider__item');
    const link = document.querySelector('.link-realisation');

    anim.set(link, { autoAlpha: 0 });

    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < targets.length; i++) {
      const splitTextItem = new SplitText(targets[i], { type: 'words, chars' });
      const { chars } = splitTextItem;
      anim.set(targets[i], { autoAlpha: 1, display: 'inline-block' });
      anim.from(chars, {
        stagger: {
          each: 0.02,
          ease: 'none',
        },
        visibility: 'hidden',
      });

      if (i === 0) {
        anim.to(link, { autoAlpha: 1 });
      }

      // change la phrase
      anim.to(targets[i], {
        autoAlpha: 0,
        duration: 0.3,
        ease: 'none',
        delay: 2,
      });
      anim.set(targets[i], { autoAlpha: 0, display: 'none' });
    }

    // gsap.to(link, { autoAlpha: 1 }, 3);

    anim.play();

    return () => {
      anim.kill();
    };
  }, [anim]);

  return (
    <>
      <IntroStyles>
        <div className="container container--md">
          <div className="fade-slider">
            <h2 className="slide__title">
              <span className="home-title__slider__item  home-title__slider__item--active">
                Nous travaillons avec des agences de communication, des
                institutions, des musées, des entreprises, des médias.
              </span>
              <span className="home-title__slider__item">
                Nous conseillons et accompagnons nos clients dans leur réflexion
                et leur stratégie.
              </span>
              <span className="home-title__slider__item">
                Nous concevons et produisons des films et des contenus créatifs
                et efficaces pour le web, le mobile, les événements et la
                télévision.
              </span>
              {/*
            <TransitionLink
              to="/realisations"
              className="link-realisation"
              exit={{
                trigger: ({ exit, node }) => fadeHeaderFooter(exit, node),
                length: 0.5,
              }}
              entry={{
                length: 0.5,
                delay: 0.3,
              }}
            >
              <Arrow />
            </TransitionLink>
           */}
            </h2>
          </div>
        </div>
      </IntroStyles>
      <Link to="/realisations" className="link-realisation">
        <Arrow />
      </Link>
    </>
  );
}
