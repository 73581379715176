import React from 'react';
import styled from 'styled-components';
import { mq } from '../styles/breakpoints';
import PortableText from './PortableText';

const SentencesStyle = styled.div`
  max-width: 31.25rem;
  padding-right: 2rem;

  ${mq[1]} {
    padding-left: 4.625rem;
    padding-right: 0;
  }

  .sentence {
    border-left: 2px solid var(--black);
    font-family: var(--font-headings);
    font-size: 1.375rem;
    line-height: calc(24 / 22);
    padding-left: 0.5rem;
  }

  strong {
    font-weight: 900;
  }
`;

export default function Sentences({ sentences }) {
  const sentence = sentences[Math.floor(Math.random() * sentences.length)];

  return (
    <SentencesStyle>
      <PortableText blocks={sentence.text} id="sentence" />
    </SentencesStyle>
  );
}
