import React from 'react';
import styled from 'styled-components';
import Facebook from '../assets/images/facebook.inline.svg';
import Twitter from '../assets/images/twitter.inline.svg';
import Vimeo from '../assets/images/vimeo.inline.svg';
import Linkedin from '../assets/images/linkedin.inline.svg';
import Sentences from './Sentences';

const HeadStyles = styled.div`
  padding-bottom: 2.9375rem;
  padding-top: 2.9375rem;

  .container {
    align-items: center;
    display: flex;
    justify-content: space-between;
  }
  .networks {
    align-items: center;
    display: flex;
    justify-content: space-between;
    margin-left: auto;

    .icon {
      margin-left: 1.25rem;
    }
  }
  .icon-vimeo {
    height: 1.6875rem;
    width: 1.875rem;
  }
  .icon-facebook {
    height: 1.75rem;
    width: 0.9375rem;
  }
  .icon-linkedin,
  .icon-twitter {
    height: 1.875rem;
    width: 1.875rem;
  }
`;

export default function Head({ networks, sentences }) {
  const { facebook, twitter, vimeo, linkedin } = networks;

  return (
    <HeadStyles className="head">
      <div className="container container--lg">
        {sentences && <Sentences sentences={sentences} />}
        <div className="networks">
          {facebook && (
            <a
              href={facebook}
              target="_blank"
              rel="noreferrer"
              className="network-link-icon"
            >
              <Facebook className="icon icon-facebook" />
            </a>
          )}
          {twitter && (
            <a
              href={twitter}
              target="_blank"
              rel="noreferrer"
              className="network-link-icon"
            >
              <Twitter className="icon icon-twitter" />
            </a>
          )}
          {vimeo && (
            <a
              href={vimeo}
              target="_blank"
              rel="noreferrer"
              className="network-link-icon"
            >
              <Vimeo className="icon icon-vimeo" />
            </a>
          )}
          {linkedin && (
            <a
              href={linkedin}
              target="_blank"
              rel="noreferrer"
              className="network-link-icon"
            >
              <Linkedin className="icon icon-linkedin" />
            </a>
          )}
        </div>
      </div>
    </HeadStyles>
  );
}
