import React from 'react';
import styled from 'styled-components';
import PortableText from '../PortableText';
import { mq } from '../../styles/breakpoints';
import trameBg from '../../assets/images/trame-net.svg';
import { pxtopc } from '../../styles/Mixins';

const TwoColumnsStyles = styled.div`
  .block-columns {
    display: flex;
    flex-wrap: wrap;

    ${mq[2]} {
      padding-left: 4.3125rem;
      padding-right: 4.3125rem;
    }
  }

  .heading-2,
  .heading-3 {
    font-family: var(--font-headings);
  }

  .bloc-metier {
    background: url(${trameBg}) center 75% no-repeat;
    background-size: 43.6875rem;
    margin-bottom: 5rem;

    .block-column {
      &:first-child {
        font-size: 0.9375rem;
        margin-top: 1.5rem;
      }
      &:last-child {
        margin-top: 1.5rem;
        > *:last-child {
          margin-bottom: 0;
        }
      }
    }

    .heading-2 {
      padding-bottom: 2rem;

      + * {
        margin-top: 0;
      }

      ${mq[1]} {
        padding-bottom: 15.625rem;
      }
    }

    .heading-3 {
      font-size: 3.125rem;
      line-height: 1.1;
      margin: 0 0 0.625rem 2.8125rem;
    }

    ${mq[1]} {
      background: url(${trameBg}) center center no-repeat;

      .block-column {
        flex-basis: 50%;
        flex-grow: 0;

        &:first-child {
          padding-right: 1.5rem;
        }

        &:last-child {
          margin-top: 0;
          padding-left: 1rem;
          position: relative;

          &:before {
            background: var(--black);
            content: '';
            display: block;
            height: calc(100% - 1.25rem);
            left: 0;
            position: absolute;
            top: 6px;
            width: 2px;
          }
        }
      }
    }
  }

  .bloc-portrait {
    margin-bottom: 5rem;

    figure {
      margin: -0.625rem -0.3125rem 0 0;

      .alignright {
        margin-left: 0;
        margin-right: -0.3125rem;
      }
    }
    .heading-2 {
      font-size: 1.75rem;
      line-height: calc(34 / 28);
      margin-bottom: 0.5em;
      text-align: right;
    }
    .heading-3 {
      font-size: 1.5625rem;
      line-height: calc(30 / 25);
      margin-bottom: 4.6875rem;
    }
    .block-column {
      &:first-child {
        > *:first-child {
          margin: 0;
        }
      }
      &:last-child {
        font-size: 0.9375rem;
      }
    }

    &--right {
      .block-column {
        &:first-child {
          order: 2;
        }
        &:last-child {
          order: 1;
        }
      }
    }

    ${mq[1]} {
      .block-column {
        &:first-child {
          padding-right: 1.5rem;
          flex-basis: ${pxtopc(323, 977)};
        }

        &:last-child {
          flex-basis: ${pxtopc(654, 977)};
          padding-left: 16px;
          position: relative;

          &:before {
            background: var(--black);
            content: '';
            display: block;
            height: 7.8125rem;
            left: 0;
            position: absolute;
            top: 0.6875rem;
            width: 2px;
          }

          > *:first-child {
            margin-top: 4.75rem;
          }
        }
      }
      &--right {
        .heading-2 {
          margin-bottom: 1em;
          text-align: left;
        }
        .block-column {
          &:first-child {
            flex-basis: ${pxtopc(654, 977)};
            text-align: right;

            > *:first-child {
              text-align: right;
              margin-top: 4.75rem;
            }
          }
          &:last-child {
            flex-basis: ${pxtopc(323, 977)};
            order: 2;

            > *:first-child {
              margin: 0;
            }
          }
        }
      }
    }
  }

  .bloc-references {
    margin-top: 1rem;
    margin-bottom: 5rem;

    .block-column {
      font-size: 0.9375rem;
    }
    .heading-3 {
      font-size: 1.25rem;
    }

    ${mq[1]} {
      .block-column {
        flex-basis: 0;
        flex-grow: 1;
        position: relative;

        > *:last-child {
          margin-bottom: 0;
        }

        &:first-child {
          padding-right: 4rem;
        }

        &:last-child {
          padding-left: 4.375rem;

          &:before {
            background: var(--black);
            content: '';
            display: block;
            height: calc(100% - 10px);
            left: 0;
            position: absolute;
            /* top: 1rem; */
            width: 2px;
          }
        }
      }
    }
  }
`;

export default function TwoColumns({ columnLeft, columnRight, className }) {
  return (
    <TwoColumnsStyles className="container container--lg">
      <div className={`block-columns ${className}`}>
        {columnLeft && (
          <div className="block-column">
            <PortableText blocks={columnLeft} />
          </div>
        )}
        {columnRight && (
          <div className="block-column">
            <PortableText blocks={columnRight} />
          </div>
        )}
      </div>
    </TwoColumnsStyles>
  );
}
