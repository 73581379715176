import React, { useContext, useRef, useEffect } from 'react';
import SanityMuxPlayer from 'sanity-mux-player';
import { VideoContext } from '../contexts/VideoContext';
import Play from '../assets/images/play.inline.svg';

const Video = ({ index, video, poster, isPlaying, setIsPlaying }, ref) => {
  const { selectedVideo, setSelectedVideo } = useContext(VideoContext);
  const { _rawAsset } = video;
  const videoRef = useRef();

  console.log(index);

  useEffect(() => {
    const vid = videoRef.current.video?.current;

    if (vid) {
      if (isPlaying === true) {
        const playing =
          vid.currentTime > 0 &&
          !vid.paused &&
          !vid.ended &&
          vid.readyState > vid.HAVE_CURRENT_DATA;

        if (!playing) {
          const playPromise = vid.play();

          if (playPromise !== undefined) {
            playPromise
              .then((_) => {
                // Automatic playback started!
                // Show playing UI.
                console.log('audio played auto');
              })
              .catch((error) => {
                // Auto-play was prevented
                // Show paused UI.
                console.log('playback prevented');
              });
          }
        }
      } else {
        videoRef.current.video?.current?.pause();
      }
    }
  }, [isPlaying]);

  useEffect(() => {
    if (index !== selectedVideo) {
      videoRef.current.video?.current?.pause();
    }
  }, [index, selectedVideo]);

  const playVideo = () => {
    setIsPlaying(!isPlaying);
    // console.log(videoRef.current);
    // videoRef.current.play();
  };

  return (
    <div className={`video ${isPlaying ? 'active' : ''}`}>
      <SanityMuxPlayer
        assetDocument={_rawAsset}
        showControls
        muted={false}
        loop={false}
        width="100%"
        ref={videoRef}
        poster={poster.src}
      />
      <button
        type="button"
        className={`poster ${isPlaying ? 'inactive' : ''}`}
        onClick={playVideo}
      >
        <Play className="play" />
      </button>
    </div>
  );
};

export default Video;
