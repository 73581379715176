import React from 'react';
import styled from 'styled-components';
import PortableText from '../PortableText';

const SentenceStyles = styled.div`
  font-family: var(--font-headings);
  .block {
    margin: 1.5625rem 0 0.5rem;
  }
  .heading-2,
  .heading-3 {
    font-size: 2.1875rem;
  }
`;

export default function Sentence({ sentence }) {
  return (
    <SentenceStyles className="container container--sm">
      <PortableText blocks={sentence} id="align-center" />
    </SentenceStyles>
  );
}
